import React from 'react';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

const PaymentDetailsModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <Container padding="1.5rem">
        <Container fontWeight={600} fontSize="1.31rem" paddingBottom="1.25rem" color="#fff">
          Your Payment Details
        </Container>
        <Container color="green" paddingBottom="1.25rem">
          Your order no. TNX033114 has been placed successfully.
        </Container>
        <Container paddingBottom="1.25rem" color="#fff">
          Please send <span style={{ color: 'green' }}>0.93123 USDT</span> to the adress below. The
          token balance will appear in your account only after your transaction gets approved.
        </Container>
        <Container paddingBottom="2.5rem" color="#fff">
          Payment for the following Tether (ERC20) Wallet Address
        </Container>
        <Container display="flex" alignItems="center" gap="1.25rem">
          <Container color="#fff">QR GOES HERE</Container>
          <Container width="100%">
            <Container display="flex" alignItems="center" gap="0.6rem">
              <Container color="#fff" marginBottom="2rem" display="flex" gap="0.6rem">
                <span>Send amount:</span> <span style={{ color: 'green' }}>99.840965 USDT</span>
              </Container>
            </Container>
            <Container width="100%" flexGrow={1}>
              <Container
                color="#fff"
                background="#111"
                padding="0.6rem"
                borderRadius={10}
                width="100%"
              >
                ADDRESS GOES HERE
              </Container>
              <Container marginTop="1.25rem">
                <Button text="View Transactions" />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Modal>
  );
};

export default PaymentDetailsModal;
