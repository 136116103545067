import React from 'react';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Input } from 'src/components/Input';

const WalletAddressModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <Container padding="1.5rem">
        <Container fontWeight={600} fontSize="1.31rem" paddingBottom="1.25rem">
          Wallet Address
        </Container>
        <Container paddingBottom="1.25rem">
          In order to receive your BXB token please select your wallet address and you have to put
          the address below input box. You will receive BXB token to this address after the token
          sale end.
        </Container>
        <Input
          label="Enter your Ethereum address"
          placeholder="Wallet address"
          type="text"
          value=""
          onChange={() => null}
        />
        <Container paddingBottom="1.25rem">
          Note: Address is the receiving wallet of an ERC20 Smart Chain on any non custodial wallet.
        </Container>
        <Container>
          DO NOT USE your exchange wallet address OR if you don`t have a private key of your
          address. You WILL NOT receive your token and you WILL LOSE YOUR FUNDS if you do.
        </Container>
      </Container>
      <Container display="flex" justifyContent="center">
        <Button text="Add Wallet" />
      </Container>
    </Modal>
  );
};

export default WalletAddressModal;
