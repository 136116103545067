import React from 'react';

import { Button } from 'src/components/Button';

const FilterButtons = ({ filterButtons, filter, setFilter }) => (
  <div className="d-flex gap-3 market-filter-btn-group">
    {filterButtons.map((filterButton, index) => (
      <Button
        key={index}
        type={filter === filterButton ? 'default' : 'outlined'}
        text={filterButton}
        onClick={() => setFilter(filterButton)}
      />
    ))}
  </div>
);

export default FilterButtons;
