import React from 'react';

import { Container } from 'src/components/Container';
import { Modal } from 'src/components/Modal';
import { AlertCopy } from './AlertCopy';
import ListItemRenderer from './ListItemRenderer';

interface ItemDetailsModalProps {
  item: { [key: string]: any };
  title?: string;
  currencies?: any;
  children?: React.ReactNode;
  onClose: () => void;
}

const ItemDetailsModal: React.FC<ItemDetailsModalProps> = ({
  item,
  title,
  currencies,
  children,
  onClose,
}) => {
  return (
    <Modal isOpen onClose={onClose}>
      {title && (
        <Container textAlign="center" fontWeight={600} fontSize="1.5rem">
          {title}
        </Container>
      )}
      {children}
      <Container display="flex" flexDirection="column" gap="0.5rem" marginTop="1rem">
        {Object.entries(item).map(([key, value]) => {
          if (typeof value === 'string' || typeof value === 'number') {
            return (
              <Container
                key={key}
                paddingBottom="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Container fontWeight={500}>{key}</Container>{' '}
                <ListItemRenderer item={item} field={key} currencies={currencies} isModal />
              </Container>
            );
          }
          return null;
        })}
      </Container>
      <AlertCopy />
    </Modal>
  );
};

export default ItemDetailsModal;
