import React from 'react';

import FinancialCrime from 'src/assets/FinancialCrime.jpeg';

import { Container } from 'src/components/Container';
import { Glass } from 'src/components/Glass';

const AboutUs = () => {
  return (
    <Container textAlign="center">
      <Glass maxWidth="80vw" margin="0 auto">
        <Container fontSize="1.75rem" paddingBottom="1.25rem">
          About Us
        </Container>
        <Container>
          <p>
            Welcome to Bxlend, a cutting-edge crypto-banking platform dedicated to revolutionizing
            the way you manage and interact with your digital assets. Based in Lithuania, Bxlend
            operates under the registered name UAB Bxlend, registration number 306723160, and
            proudly holds a crypto exchange license. At Bxlend, we provide a comprehensive range of
            services designed to meet the diverse needs of cryptocurrency users across the European
            Union. Our offerings include secure and efficient management of your crypto assets,
            ensuring that your experience with us is both seamless and trustworthy. Our registration
            number stands as a testament to our commitment to compliance and excellence in the
            crypto space. This ensures that we adhere to the highest standards of security and
            reliability, giving our users peace of mind as they navigate the dynamic world of
            cryptocurrencies.
          </p>
          <Container padding="3rem 0">
            <Container fontWeight={600} fontSize="1.5rem">
              <span>Licenses</span> & Registrations
            </Container>
          </Container>
        </Container>
        <Container>
          <Container margin="0 auto" border="1px solid #ccc" padding="1.25rem" width="fit-content">
            <Container marginBottom="1.25rem">
              <img src={FinancialCrime} width="5rem" />
            </Container>
            <Container letterSpacing="0.2rem">LITHUANIA</Container>
            <Container fontWeight={600} fontSize="1.5rem">
              Financial Crime <br /> Investigation Service
            </Container>
            <Container paddingBottom="2rem" color="gray">
              Execution of Virtual Currency Exchange <br /> Operator and Deposit of Virtual Currency{' '}
              <br /> Wallets Operator
            </Container>
            <Container paddingBottom="1.25rem" color="gray">
              Reference NO. <br /> 306723160
            </Container>
            <Container paddingBottom="1.25rem" color="gray">
              COMPANY <br /> BxLend UAB
            </Container>
            <Container paddingBottom="2rem" color="gray">
              <a
                href="https://www.registrucentras.lt/jar/sarasai/vvko.php"
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  wordBreak: 'break-word',
                  marginBottom: '1.25rem',
                }}
              >
                https://www.registrucentras.lt/jar/sarasai/vvko.php
              </a>
              <a
                href="https://www.registrucentras.lt/jar/sarasai/dvvpo."
                style={{ display: 'block', maxWidth: '100%', wordBreak: 'break-word' }}
              >
                https://www.registrucentras.lt/jar/sarasai/dvvpo.
              </a>
            </Container>
          </Container>
        </Container>
      </Glass>
    </Container>
  );
};

export default AboutUs;
