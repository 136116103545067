import styled from 'styled-components';

export const StyledWrap = styled.div`
  max-width: 25rem;
  border: 1px solid #ccc;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 4% 2%;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0.62rem;
`;

export const StyledInput = styled.input`
  margin-bottom: 1.25rem;
  padding: 0.31rem 1.25rem;
  width: 100%;
  outline: none;
  text-align: center;
  border: 1px solid #ccc;
`;
