import React from 'react';

import { Container } from 'src/components/Container';

const StepsGuide = () => {
  return (
    <Container padding="1.25rem 1.25rem 40px" display="flex" gap="3%">
      <Container width="25%">
        <Container display="flex" alignItems="center" gap="3%" paddingBottom="1rem">
          <Container
            width="2.5rem"
            height="2.5rem"
            borderRadius="50%"
            background="rgb(0, 254, 185)"
            fontSize="1rem"
            display="flex"
            justifyContent="center"
            flexShrink={0}
            alignItems="center"
            color="#172A4F !important"
          >
            1
          </Container>
          <Container border="2px solid rgb(0, 254, 185)" width="100%"></Container>
        </Container>
        <Container fontSize="0.8rem" fontWeight={600}>
          Select the Token and Copy the Wallet Address
        </Container>
        <Container color="grey" fontSize="0.8rem">
          Withdraw to Wallet Address
        </Container>
      </Container>
      <Container width="25%">
        <Container display="flex" alignItems="center" gap="3%" paddingBottom="1rem">
          <Container
            width="2.5rem"
            height="2.5rem"
            borderRadius="50%"
            background="rgb(0, 254, 185)"
            fontSize="1.12rem"
            display="flex"
            justifyContent="center"
            flexShrink={0}
            alignItems="center"
            color="#172A4F !important"
          >
            2
          </Container>
          <Container border="2px solid rgb(0, 254, 185)" width="100%"></Container>
        </Container>
        <Container fontSize="0.8rem" fontWeight={600}>
          Withdraw to Wallet Address
        </Container>
        <Container color="grey" fontSize="0.8rem">
          Paste the copied wallet address on other exchange platforms and submit a withdrawal
          application
        </Container>
      </Container>
      <Container width="25%">
        <Container display="flex" alignItems="center" gap="3%" paddingBottom="1rem">
          <Container
            width="2.5rem"
            height="2.5rem"
            borderRadius="50%"
            background="rgb(0, 254, 185)"
            fontSize="1rem"
            display="flex"
            justifyContent="center"
            flexShrink={0}
            alignItems="center"
            color="#172A4F !important"
          >
            3
          </Container>
          <Container border="2px solid rgb(0, 254, 185)" width="100%"></Container>
        </Container>
        <Container fontSize="0.8rem" fontWeight={600}>
          Transfer Confirmation
        </Container>
        <Container color="grey" fontSize="0.8rem">
          Wait for the blockchain network to confirm the transfer
        </Container>
      </Container>
      <Container width="25%">
        <Container display="flex" alignItems="center" paddingBottom="1rem">
          <Container
            width="2.5rem"
            height="2.5rem"
            borderRadius="50%"
            background="rgb(0, 254, 185)"
            fontSize="1rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#172A4F !important"
          >
            4
          </Container>
        </Container>
        <Container fontSize="0.8rem" fontWeight={600}>
          Deposit Successfull
        </Container>
        <Container color="grey" fontSize="0.8rem">
          After the blockchain network has confirmed the transfer, BxLend will transfer the asset to
          your wallet address
        </Container>
      </Container>
    </Container>
  );
};

export default StepsGuide;
