import Image from 'react-bootstrap/Image';

import { Container } from 'src/components/Container';

import '../index.css';

interface IPortfolioItem {
  id: number;
  title: string;
  content: string;
  url: string;
  align?: boolean;
}

const PortfolioItem: React.FC<IPortfolioItem> = ({ id, title, content, url, align }) => {
  return (
    <div
      className={align ? 'row mt-5 justify-content-between' : 'row mt-5'}
      style={{ alignItems: 'center' }}
    >
      <div className="d-flex col-md-8 col-12 mt-3 mb-3">
        <div className="item-number">{id}.</div>
        <div className="portfolio-content ms-5">
          <div className="title">{title}</div>
          <div className="content mt-3">{content}</div>
        </div>
      </div>
      <Container width="fit-content" margin={align ? '0 0 0 auto' : '0 auto'}>
        <Image src={url} width="100%" style={{ maxHeight: '6rem', maxWidth: '6.5rem' }} />
      </Container>
    </div>
  );
};

export default PortfolioItem;
