import React, { useState } from 'react';

import { Container } from 'src/components/Container';
import { Input } from 'src/components/Input';
import { Glass } from 'src/components/Glass';
import { Table } from 'src/components/Table';
import PaymentDetailsModal from './components/PaymentDetailsModal';

const PresaleTransactions = () => {
  const [open, setOpen] = useState(false);

  const TRANSACTIONS = [
    {
      trxId: 'TNX016132',
      date: '25 Nov, 2024 09:10 AM',
      tokens: '+1,250 DTX',
      amount: '99.7353 USDT',
      usdAmount: '100 USD',
      address: '0x886...0De1E',
      type: 'Purchase',
    },
    {
      trxId: 'TNX016132',
      date: '25 Nov, 2024 09:10 AM',
      tokens: '+1,250 DTX',
      amount: '99.7353 USDT',
      usdAmount: '100 USD',
      address: '0x886...0De1E',
      type: 'Purchase',
    },
    {
      trxId: 'TNX016132',
      date: '25 Nov, 2024 09:10 AM',
      tokens: '+1,250 DTX',
      amount: '99.7353 USDT',
      usdAmount: '100 USD',
      address: '0x886...0De1E',
      type: 'Purchase',
    },
  ];

  return (
    <>
      <Glass>
        <Container textAlign="center" fontSize="1.75rem" paddingBottom="1.25rem">
          This table shows all types and status of transactions
        </Container>
        <Container padding="1.25rem" border="1px solid #444" borderRadius="0.6rem">
          <Container display="flex" justifyContent="flex-end" marginBottom="0.6rem">
            <Input placeholder="Enter Search Results" value="" onChange={() => null} />
          </Container>

          <Table
            headers={[
              'Transaction NO',
              'Transaction Date',
              'Tokens',
              'Amount',
              'USD Amount',
              'To',
              'Type',
            ]}
            items={TRANSACTIONS}
            columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
            currentPage={1}
            setCurrentPage={() => null}
            totalPages={1}
            fieldsToShow={['trxId', 'date', 'tokens', 'amount', 'usdAmount', 'address', 'type']}
          />
          <Container display="grid" gridTemplateColumns="repeat(8, 1fr)" gap="0px"></Container>
        </Container>
      </Glass>
      <PaymentDetailsModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default PresaleTransactions;
