import React from 'react';

import { Container } from 'src/components/Container';
import { Input } from 'src/components/Input';
import { Select } from 'src/components/Select';
import { Button } from 'src/components/Button';
import GradientedArea from 'src/pages/Presale/components/GradientedArea';

import { PRESALE_CURRENCIES } from 'src/constants';

const StepTwo = () => {
  return (
    <Container padding="1.25rem" border="1px solid #ccc" borderRadius={20}>
      <Container textAlign="center" fontSize="1.75rem" paddingBottom="1.25rem">
        Step 2
      </Container>
      <Container paddingBottom="1rem">Please select payment method</Container>
      <Container display="flex" gap="1.25rem" flexWrap="wrap">
        <Container flexGrow={2}>
          <Input
            label="You send"
            type="number"
            placeholder="0.000000"
            value="0"
            onChange={() => null}
          />
        </Container>
        <Container flexGrow={1}>
          <Select
            label="Select a currency"
            options={PRESALE_CURRENCIES}
            value=""
            onChange={() => null}
          />
        </Container>
      </Container>
      <Container color="green" textAlign="right" marginBottom="1.5rem" cursor="pointer">
        <Button text="Apply Promo code" />
      </Container>
      <Container textAlign="center" fontSize="1.25rem">
        You Get:
      </Container>
      <Container padding="1.25rem 0" fontSize="1.25rem" textAlign="center">
        0.00 $BXB = 0.000000 BTC
      </Container>
      <GradientedArea>
        <Container display="flex" justifyContent="space-between">
          <Container>Token Ordered:</Container>
          <Container>0 BXB</Container>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Container>Purchase Bonus:</Container>
          <Container>0 BXB</Container>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Container>Amount Bonus:</Container>
          <Container>0 BXB</Container>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Container>Promo Bonus:</Container>
          <Container>0 BXB</Container>
        </Container>
      </GradientedArea>
    </Container>
  );
};

export default StepTwo;
