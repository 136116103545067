import React from 'react';

import { Container } from 'src/components/Container';
import { Input } from 'src/components/Input';
import GradientedArea from 'src/pages/Presale/components/GradientedArea';

const StepOne = () => {
  return (
    <Container padding="1.25rem" border="1px solid #ccc" borderRadius="1.25rem">
      <Container textAlign="center" paddingBottom="1.25rem" fontSize="1.75rem">
        Step 1
      </Container>
      <Container paddingBottom="1.25rem">
        Enter the amount in USD you want spend to purchase BXB tokens.
      </Container>
      <Input label="Amount in USD" value="" onChange={() => null} />
      <Container display="flex" justifyContent="space-around" flexWrap="wrap">
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing="0.6rem">BONUS</Container>
              <Container fontSize="3.75rem">5%</Container>
            </Container>
          </GradientedArea>
          <Container>
            Deposit <span style={{ color: 'green' }}>250.00</span>
            <span> USD</span>
            <Container color="#fff">
              to <span style={{ color: 'green' }}>1999.99</span>
              <span> USD</span>
            </Container>
          </Container>
        </Container>
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing={10}>BONUS</Container>
              <Container fontSize="3.75rem">10%</Container>
            </Container>
          </GradientedArea>
          <Container>
            <Container>
              Deposit <span style={{ color: 'green' }}>2000.00</span>
              <span style={{ color: '#fff' }}> USD</span>
              <Container color="#fff">
                to <span style={{ color: 'green' }}>4999.99</span>
                <span style={{ color: '#fff' }}> USD</span>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing={10}>BONUS</Container>
              <Container fontSize="3.75rem">15%</Container>
            </Container>
          </GradientedArea>
          <Container>
            Deposit over{' '}
            <Container>
              <span style={{ color: 'green' }}>5000.00</span> USD
            </Container>
          </Container>
        </Container>
      </Container>
      <Container display="flex" justifyContent="space-around"></Container>
    </Container>
  );
};

export default StepOne;
