import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';
import { fetchCountries } from 'src/store/slice/countries';
import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Select } from 'src/components/Select';
import { Glass } from 'src/components/Glass';
import { Loader } from 'src/components/Loader';

import { StyledInputEmailWrap, StyledSendCode } from '../EnterEmail/styled';

const EnterPhone = () => {
  const [hasPhone, setHasPhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [sendCodeLabel, setSendCodeLabel] = useState('Send');
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({ phone_code: '', code: '' });

  const dispatch = useDispatch();
  const {
    user: { id, phone_number },
  } = useSelector(({ user }) => user);
  const { countries, loading: countriesLoading } = useSelector(({ countries }) => countries);

  useEffect(() => {
    if (!countries.length) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countries]);

  const isFormFilled = phone && password;

  const handleCode = () => {
    request.post(`${PUBLIC_URL}/v1/users/${id}/phone-number/verification/resend`, null).then(() => {
      if (sendCodeLabel === 'Send') {
        setSendCodeLabel('Resend');
      }
      dispatch(
        setAppAlert({
          message: 'The code has been sent to your phone number.',
          isSuccess: true,
        }),
      );
    });
  };

  const handleSubmit = () => {
    if (!hasPhone) {
      setLoading(true);
      const phoneWithPrefix = `+${selectedCountry.phone_code}${phone}`;

      request
        .patch(`${PUBLIC_URL}/v1/users/${id}/phone-number/`, {
          phone_number: phoneWithPrefix,
          password,
        })
        .then(() => {
          dispatch(
            setAppAlert({
              message: 'The code has been sent to your phone number.',
              isSuccess: true,
            }),
          );
          setHasPhone(true);
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Phone number or password is incorrect.',
              isSuccess: false,
            }),
          );
          setPhone('');
          setPassword('');
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      request
        .post(`${PUBLIC_URL}/v1/users/${id}/phone-number/verification/resend`, null)
        .then(() => {
          dispatch(
            setAppAlert({
              message: 'The code has been sent to your phone number.',
              isSuccess: true,
            }),
          );
          setHasPhone(true);
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Phone number or password is incorrect.',
              isSuccess: false,
            }),
          );
          setPhone('');
          setPassword('');
        })
        .finally(() => setLoading(false));
    }
  };

  if (countriesLoading) {
    return <Loader overlay />;
  }

  return (
    <Container height="100%" display="flex" alignItems="center" justifyContent="center">
      <Container>
        <Glass>
          <Container fontSize="1.75rem" paddingBottom="1.25rem">
            {hasPhone ? 'Phone verification' : 'Update phone number'}
          </Container>
          {hasPhone && (
            <p>
              Please enter the 6 digits verification code that was sent to {phone_number}.{' '}
              <Container
                fontWeight={600}
                display="inline"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => setHasPhone(false)}
              >
                Edit
              </Container>
              <Container>The code is valid for 2 minutes.</Container>
            </p>
          )}
          <Container>
            <StyledInputEmailWrap>
              <Container display="flex" alignItems="flex-end" gap="1rem">
                <Select
                  label="Country Code"
                  placeholder="Select Code..."
                  value={selectedCountry.code}
                  options={countries.map((country) => ({
                    value: country.code,
                    label: `${country.code} ${country.phone_code}`,
                  }))}
                  onChange={(e) => {
                    const selected = countries.find((country) => country.code === e.target.value);
                    setSelectedCountry(selected || { phone_code: '', code: '' });
                  }}
                />

                <Input
                  label={
                    hasPhone && !phone && !password
                      ? 'Phone verification code'
                      : 'Personal phone number'
                  }
                  value={hasPhone ? code : phone}
                  onChange={(e) =>
                    hasPhone
                      ? setCode(e.currentTarget.value)
                      : setPhone(e.currentTarget.value.replace(/\D/g, ''))
                  }
                />
              </Container>
              {hasPhone && (
                <StyledSendCode onClick={handleCode}>{sendCodeLabel} code</StyledSendCode>
              )}
            </StyledInputEmailWrap>
            {!hasPhone && (
              <Input
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            )}
            <br />
            <Button
              text={hasPhone ? 'Submit' : 'Update phone number'}
              isLoading={loading}
              disabled={!(isFormFilled && !hasPhone) || code.length === 6}
              $fullWidth
              onClick={handleSubmit}
            />
          </Container>
        </Glass>
      </Container>
    </Container>
  );
};

export default EnterPhone;
