import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_BUY_TOKEN } from 'src/routes';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import ProgressBar from './ProgressSection';
import GradientedArea from './GradientedArea';

import { StyledRoundSection } from '../styled';

const RoundSection = () => {
  const navigate = useNavigate();
  const percentage = 40;

  return (
    <StyledRoundSection>
      <Container fontSize="1.5rem" paddingBottom="1.25rem" textAlign="center">
        Fifth round
      </Container>
      <Container display="flex" justifyContent="space-between" alignItems="center">
        <Container fontSize="1.25rem" marginBottom="0.6rem">
          Token remaining in stage:
        </Container>
        <Container>11,018,914.5</Container>
      </Container>
      <GradientedArea padding="1rem 0 0">
        <Container
          display="flex"
          alignItems="center"
          border="1px solid #ccc"
          gap="1rem"
          borderRadius="1.25rem"
          padding="1rem"
        >
          <ProgressBar percentage={30} />
          <Container display="flex" gap="0.3rem" fontSize="1rem" fontWeight={600}>
            <span>SOLD</span>
            <span>{percentage} %</span>
          </Container>
        </Container>
      </GradientedArea>
      <GradientedArea>
        <Container display="flex" justifyContent="space-between">
          <Container>$BXB price in current stage:</Container>
          <Container>0.1 USD</Container>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Container>The next stage price:</Container>
          <Container>0.12 USD</Container>
        </Container>
      </GradientedArea>
      <GradientedArea>
        <Container display="flex" justifyContent="space-between">
          <Container>Tokens Sold In Total:</Container>
          <Container>8,337,658.95</Container>
        </Container>
      </GradientedArea>
      <Container display="flex" justifyContent="center">
        <Button text="Buy Token Now" onClick={() => navigate(ROUTE_BUY_TOKEN)} />
      </Container>
    </StyledRoundSection>
  );
};

export default RoundSection;
