import styled from 'styled-components';

export const StyledInputEmailWrap = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledSendCode = styled.span`
  position: absolute;
  top: 25%;
  right: 1.25rem;
  cursor: pointer;
  color: #111 !important;
`;
