import React, { useState } from 'react';

import { Container } from 'src/components/Container';
import { Glass } from 'src/components/Glass';
import { Button } from 'src/components/Button';
import RoundSection from './components/RoundSection';
import TokenBalance from './components/TokenBalance';
import WalletAddressModal from '../BuyTokens/components/WalletAddressModal';

const Presale = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Glass>
        <Container fontSize="1.75rem" textAlign="center" paddingBottom="1.25rem">
          Presale
        </Container>
        <Container display="flex" justifyContent="center" gap="2rem" flexWrap="wrap">
          <RoundSection />
          <Container>
            <TokenBalance />
            <Container
              border="1px solid #ccc"
              borderRadius="1.25rem"
              padding="1.25rem"
              marginTop="1.25rem"
            >
              <Container
                display="flex"
                gap="1.25rem"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="0.6rem"
              >
                <Container fontWeight={600}>ACCOUNT STATUS</Container>
                <Container padding="0.2rem 0" borderRadius="0.25rem">
                  <Button type="outlined" text="Email unverified (Resend Email)" />
                </Container>
              </Container>
              <Container
                display="flex"
                gap="1.25rem"
                alignItems="center"
                justifyContent="space-between"
              >
                <Container fontWeight={600}>RECEIVING WALLET</Container>
                <Container display="flex" alignItems="center" gap="1rem">
                  <Container fontSize="0.85rem">Add Your Wallet Address</Container>
                  <Button type="outlined" text="Add" onClick={() => setOpen(true)} />
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Glass>
      <WalletAddressModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Presale;
