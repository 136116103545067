import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_PRESALE_TRANSACTIONS } from 'src/routes';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import { Glass } from 'src/components/Glass';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';

const BuyTokens = () => {
  const navigate = useNavigate();

  return (
    <Glass>
      <Container padding="3rem">
        <StepOne />
        <Container marginBottom="1.25rem" />
        <StepTwo />
        <Container display="flex" justifyContent="center" gap="1rem" margin="1.25rem 0">
          <input type="checkbox" />
          <Container fontWeight={600}>
            I hereby agree to the <a href="#">payment agreement and token sale terms *</a>
          </Container>
        </Container>
        <Container display="flex" justifyContent="center">
          <Button text="Pay with Crypto" onClick={() => navigate(ROUTE_PRESALE_TRANSACTIONS)} />
        </Container>
      </Container>
    </Glass>
  );
};

export default BuyTokens;
