import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import PortfolioItem from './PortfolioItem';

import { getSign } from 'src/constants';

const Portfolio = () => {
  const sign = getSign();

  const { user } = useSelector(({ user }) => user);

  return (
    <div className="mt-5 pt-5" id="portfolio">
      <Container fontSize="2.2rem">Build your own crypto portfolio</Container>
      <div className="portfolio-subtitle ms-4">Start your first trade with these easy steps.</div>
      <PortfolioItem
        id={1}
        title="Verify your identity"
        content="Complete the identity verification process to secure your account and transactions."
        url="./assets/portfolio-1.png"
      />
      <PortfolioItem
        id={2}
        title="Fund your account"
        content="Add funds to your crypto account to start trading crypto. You can add funds with a variety of payment methods."
        url="./assets/portfolio-2.png"
        align
      />
      <PortfolioItem
        id={3}
        title="Start trading"
        content="You’re good to go! Buy/sell crypto, set up your account and discover what BxLend has to offer."
        url="./assets/portfolio-3.png"
      />
      <div className="get-started-btn mt-5 pt-5 d-flex justify-content-center">
        <Link to={user.id ? '/market' : `${sign}&action=signup`} style={{ textDecoration: 'none' }}>
          <Button text="Get Started" />
        </Link>
      </div>
    </div>
  );
};

export default Portfolio;
