import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as ChatSupport } from 'src/assets/ChatSupport.svg';
import { ReactComponent as FAQ } from 'src/assets/FAQ.svg';
import { ReactComponent as Blog } from 'src/assets/Blog.svg';

import { Container } from 'src/components/Container';

import { ROUTE_FAQ } from 'src/routes';

const HelpSection = () => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  const iconColor = isDark ? '#fff' : '#172a4f';

  return (
    <div className="mt-5 pt-5">
      <Container fontSize="2.2rem">Need Help?</Container>
      <div className="d-flex justify-content-around help-content-align">
        <a style={{ textDecoration: 'none' }} href="mailto:customercare@bxlend.com">
          <div className="d-flex align-items-center flex-column pt-5">
            <ChatSupport fill={iconColor} width="5rem" />
            <Container fontSize="1.25rem" paddingTop="0.6rem">
              Chat support
            </Container>
          </div>
        </a>
        <Link style={{ textDecoration: 'none' }} to={ROUTE_FAQ}>
          <div className="d-flex align-items-center flex-column pt-5">
            <FAQ fill={iconColor} width="5rem" />
            <Container fontSize="1.25rem" paddingTop="0.6rem">
              FAQ
            </Container>
          </div>
        </Link>
        <a style={{ textDecoration: 'none' }} href="https://medium.com/@bxlend">
          <div className="d-flex align-items-center flex-column pt-5">
            <Blog stroke={iconColor} width="5rem" />
            <Container fontSize="1.25rem" paddingTop="0.6rem">
              Blog
            </Container>
          </div>
        </a>
      </div>
    </div>
  );
};

export default HelpSection;
