import React from 'react';
import { styled } from 'styled-components';

interface IGlass {
  children: React.ReactNode;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  className?: string;
}

const GlassContainer = styled.div<{
  $margin?: string;
  $padding?: string;
  $width?: string;
  $maxWidth?: string;
}>`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: ${({ $width }) => $width ?? '100%'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? '100%'};
  padding: ${({ $padding }) => $padding ?? '1.5rem'};
  margin: ${({ $margin }) => $margin ?? '0'};
`;

export const Glass: React.FC<IGlass> = ({
  children,
  margin,
  padding,
  width,
  maxWidth,
  className,
}) => {
  return (
    <GlassContainer
      className={className}
      $padding={padding}
      $margin={margin}
      $width={width}
      $maxWidth={maxWidth}
    >
      {children}
    </GlassContainer>
  );
};
