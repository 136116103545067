import React, { useState, useEffect } from 'react';

import { Container } from 'src/components/Container';
import { Glass } from 'src/components/Glass';

import { FAQ_DATA } from 'src/constants';

import { NextButton, PrevButton, SliderContainer } from './styled';

const SLIDE_COUNT = FAQ_DATA.length;
const CLONE_COUNT = 1;

const FAQCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(CLONE_COUNT);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  useEffect(() => {
    if (currentIndex === SLIDE_COUNT + CLONE_COUNT) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(CLONE_COUNT);
      }, 600);
    } else if (currentIndex === 0) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(SLIDE_COUNT);
      }, 600);
    } else {
      setTimeout(() => setIsTransitioning(false), 600);
    }
  }, [currentIndex]);

  const slides = [...FAQ_DATA.slice(-CLONE_COUNT), ...FAQ_DATA, ...FAQ_DATA.slice(0, CLONE_COUNT)];

  return (
    <Container
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="3rem"
    >
      <Glass>
        <Container fontSize="1.75rem" textAlign="center" paddingBottom="1.25rem">
          Frequently Asked Questions
        </Container>
        <PrevButton onClick={handlePrev}>{'<'}</PrevButton>
        <SliderContainer
          $translateX={(-currentIndex * 100) / 3}
          $noTransition={currentIndex === 0 || currentIndex === SLIDE_COUNT + CLONE_COUNT}
        >
          {slides.map((faq, index) => (
            <Container
              key={index}
              flex="0 0 33.33%"
              padding="1.25rem"
              box-sizing="border-box"
              margin={10}
              borderRadius={15}
              boxShadow="0px 0px 15px 3px rgba(0, 0, 0, 0.25)"
            >
              <Container fontSize="1.25rem" fontWeight={600} marginBottom="0.6rem">
                {faq.question}
              </Container>
              <Container>{faq.answer}</Container>
            </Container>
          ))}
        </SliderContainer>
        <NextButton onClick={handleNext}>{'>'}</NextButton>
      </Glass>
    </Container>
  );
};

export default FAQCarousel;
