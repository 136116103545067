import React from 'react';

import { Container } from 'src/components/Container';

const Join = () => {
  return (
    <div className="mt-5 pt-5">
      <Container fontSize="2.2rem">BxLend is a crypto exchange for everyone.</Container>
      <div className="d-flex justify-content-around align-items mt-5 join-content">
        <Container>
          <Container fontSize="3rem" textAlign="center">
            1M+
          </Container>
          <Container fontSize="1.25rem" textAlign="center">
            Verified users
          </Container>
        </Container>
        <Container>
          <Container fontSize="3rem" textAlign="center">
            99+
          </Container>
          <Container fontSize="1.25rem" textAlign="center">
            Countries supported
          </Container>
        </Container>
        <Container>
          <Container fontSize="3rem" textAlign="center">
            $99B+
          </Container>
          <Container fontSize="1.25rem" textAlign="center">
            Quarterly trading volume
          </Container>
        </Container>
      </div>
    </div>
  );
};

export default Join;
