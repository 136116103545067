import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Sun } from 'src/assets/Sun.svg';
import { ReactComponent as Moon } from 'src/assets/Moon.svg';

import { useDispatch } from 'src/store/useDispatch';
import { setTheme } from 'src/store/slice/theme';

import { StyledToggleCircle, StyledToggleSlider, StyledToggleWrapper } from './styled';

const ThemeToggle = () => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  const dispatch = useDispatch();

  return (
    <StyledToggleWrapper onClick={() => dispatch(setTheme())}>
      <StyledToggleSlider $isOn={isDark}>
        {isDark ? (
          <Sun
            width="1rem"
            height="1rem"
            style={{ position: 'absolute', top: '0.3rem', left: '0.2rem', zIndex: 10 }}
          />
        ) : null}
        <StyledToggleCircle $isOn={isDark} />
        {!isDark ? (
          <Moon
            width="1rem"
            height="1rem"
            style={{ position: 'absolute', top: '0.25rem', right: '0.2rem', zIndex: 10 }}
          />
        ) : null}
      </StyledToggleSlider>
    </StyledToggleWrapper>
  );
};

export default ThemeToggle;
