import { Link } from 'react-router-dom';

import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';

import { getSign } from 'src/constants';

const StartToday = ({ user }) => {
  const sign = getSign();

  return (
    <div className="pt-5 mt-5 start-style">
      <div className="start-title-style">Start earning today</div>
      <Container padding="4rem 0 4rem" textAlign="center">
        <Link to={user.id ? '/market' : `${sign}&action=signup`} style={{ textDecoration: 'none' }}>
          <Button text="Join us now" />
        </Link>
      </Container>
    </div>
  );
};

export default StartToday;
