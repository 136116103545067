import React from 'react';

import { Container } from 'src/components/Container';
import GradientedArea from './GradientedArea';

import { StyledRoundSection } from '../styled';

const TokenBalance = () => {
  return (
    <StyledRoundSection>
      <Container fontSize="1.5rem" paddingBottom="1.25rem" textAlign="center">
        Token Balance
      </Container>
      <Container marginBottom="0.6rem">0 $BXB</Container>
      <GradientedArea>
        <Container display="flex" flexDirection="column" justifyContent="space-between">
          <Container>Balance in other currencies:</Container>
          <Container display="flex" justifyContent="space-between">
            <span>USD</span>
            <span>~</span>
          </Container>
          <Container display="flex" justifyContent="space-between">
            <span>BTC</span>
            <span>~</span>
          </Container>
          <Container display="flex" justifyContent="space-between">
            <span>ETH</span>
            <span>~</span>
          </Container>
        </Container>
      </GradientedArea>
    </StyledRoundSection>
  );
};

export default TokenBalance;
