import React from 'react';

import Dropdown from 'src/components/Dropdown';

const SortOption = ({ label, onClick }) => (
  <li>
    <button className="dropdown-item" onClick={onClick}>
      {label}
    </button>
  </li>
);

const SortDropdown = ({ sortBy, setSort }) => (
  <div className="market-sort-btn dropdown px-2">
    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
      {sortBy || 'Sort by'}
    </button>
    <Dropdown>
      <SortOption label="Name" onClick={() => setSort('Name')} />
      <SortOption label="Price" onClick={() => setSort('Price')} />
      <SortOption label="24h Change" onClick={() => setSort('24 Change')} />
    </Dropdown>
  </div>
);

export default SortDropdown;
