import React from 'react';
import { Label, StyledInput, Wrapper } from './styled';

export interface InputProps {
  type?: string;
  label?: string;
  placeholder?: string;
  value: string;
  onKeyPress?: any;
  onFocus?: any;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Input: React.FC<InputProps> = ({
  type = 'text',
  disabled = false,
  label,
  placeholder,
  value,
  onKeyPress,
  onFocus,
  onChange,
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        disabled={disabled}
      />
    </Wrapper>
  );
};
